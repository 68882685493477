import eth from '../../assets/img/ic_eth_40.svg'
import usdc from '../../assets/img/ic_usdc_24.svg';
import btc from '../../assets/img/ic_btc.b_40.svg';
import bnb from '../../assets/img/ic_bnb_40.svg';
import uni from '../../assets/img/ic_uni_40.svg';
import matic from '../../assets/img/matic.svg';

export const tokenList = [
  {
    name: 'USDC',
    desc: 'USD Coin',
    count: '$0.99',
    percent: '-1.13%',
    img: usdc,
  },
  {
    name: 'BTC',
    desc: 'Bitcoin',
    count: '$67826.92',
    percent: '+0.15%',
    img: btc,
  },
  {
    name: 'MATIC',
    desc: 'Polygon',
    count: '$0.6992',
    percent: '+2.5%',
    img: matic,
  },
  {
    name: 'USDC',
    desc: 'USDC',
    count: '$0.99',
    percent: '+0.25%',
    img: usdc,
  },
  {
    name: 'BNB',
    desc: 'Binance',
    count: '$591.87',
    percent: '+0.21%',
    img: bnb,
  },
  {
    name: 'Uni',
    desc: 'Uniswap',
    count: '$10.59',
    percent: '+1.34%',
    img: uni,
  },
  {
    name: 'BTC',
    desc: 'Bitcoin',
    count: '$67826.92',
    percent: '+0.25%',
    img: btc,
  },
  {
    name: 'ETH',
    desc: 'Ethereum',
    count: '$3167.92',
    percent: '+0.25%',
    img: eth,
  },
  {
    name: 'BTC',
    desc: 'Bitcoin',
    count: '$67826.92',
    percent: '+0.25%',
    img: btc,
  },
  {
    name: 'ETH',
    desc: 'Ethereum',
    count: '$3167.92',
    percent: '+0.25%',
    img: eth,
  },
  {
    name: 'BTC',
    desc: 'Bitcoin',
    count: '$67826.92',
    percent: '+0.25%',
    img: btc,
  },
  {
    name: 'ETH',
    desc: 'Ethereum',
    count: '$3167.92',
    percent: '+0.25%',
    img: eth,
  },
  {
    name: 'Uni',
    desc: 'Uniswap',
    count: '$10.59',
    percent: '+1.34%',
    img: uni,
  },
  {
    name: 'BTC',
    desc: 'Bitcoin',
    count: '$67826.92',
    percent: '+0.25%',
    img: btc,
  },
  {
    name: 'MATIC',
    desc: 'Polygon',
    count: '$0.6992',
    percent: '+2.5%',
    img: matic,
  },
  {
    name: 'BTC',
    desc: 'Bitcoin',
    count: '$67826.92',
    percent: '+0.25%',
    img: btc,
  },
  {
    name: 'BNB',
    desc: 'Binance',
    count: '$593.35',
    percent: '+0.21%',
    img: bnb,
  },
  {
    name: 'Uni',
    desc: 'Uniswap',
    count: '$10.59',
    percent: '+1.34%',
    img: uni,
  },
  {
    name: 'ETH',
    desc: 'Ethereum',
    count: '$3167.92',
    percent: '+0.25%',
    img: eth,
  },
  {
    name: 'BTC',
    desc: 'Bitcoin',
    count: '$67826.92',
    percent: '+0.25%',
    img: btc,
  },
  {
    name: 'ETH',
    desc: 'Ethereum',
    count: '$3167.92',
    percent: '+0.25%',
    img: eth,
  },
  {
    name: 'BNB',
    desc: 'Binance',
    count: '$593.35',
    percent: '+0.21%',
    img: bnb,
  },
  {
    name: 'Uni',
    desc: 'Uniswap',
    count: '$10.59',
    percent: '+1.34%',
    img: uni,
  },
];
